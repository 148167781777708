<template>
  <div class="web-footer">
    <div class="f-wrap">
      <div class="web-footer-links" v-if="linkList && linkList.length > 0">
        <div class="web-footer-links-title">智库体系</div>
        <div class="web-footer-links-list">
          <div class="web-footer-links-list-item" v-for="(item, index) in linkList" :key="index">
            <a :href="item.link" :target="item.target ? '_blank' : '_self'">{{ item.name }}</a>
          </div>
        </div>
      </div>
      <div class="web-footer-bottom">
        <div class="web-footer-bottom-wechat">
          <!-- <div class="icon" v-if="$store.getters.getWebSiteQrcode">
            <img :src="$store.getters.getWebSiteQrcode" :title="$store.getters.getWebsiteName" :alt="$store.getters.getWebsiteName" />
          </div>
         <div class="title" v-if="$store.getters.getWebSiteQrcode">扫码关注SFI微信</div> -->
        </div>
        <div class="web-footer-bottom-right">
          <div class="privacy">
<!--            <a href="/detail/23" target="_blank" class="link">隐私条款</a>-->
          </div>
          <div class="copyright">
            <span>{{ $store.getters.getBanQuanXinXi }}</span>
            <span>
              <a :href="$store.getters.getBeiAnUrl" target="_blank" v-if="$store.getters.getBeiAn && $store.getters.getBeiAnUrl">
                {{ $store.getters.getBeiAn }}
              </a>
              <template v-else>
                {{ $store.getters.getBeiAn }}
              </template>
            </span>
            <span class="s">
              <a :href="$store.getters.getGonGanUrl" target="_blank" v-if="$store.getters.getGonGan && $store.getters.getGonGanUrl">
                {{ $store.getters.getGonGan }}
              </a>
              <template v-else>
                {{ $store.getters.getGonGan }}
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { friendshipLinks } from "@/api";
export default {
  name: "WebFooter",
  data() {
    return {
      linkList: []
    }
  },
  created() {
    this.getFriendLinks();
  },
  methods: {
    // 获取友情链接
    async getFriendLinks() {
      let param = {
        pageSize: 100,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "status:1",
        searchFields: "status:="
      };
      let res = await friendshipLinks(param);
      if (res && res.code == 200) {
        this.linkList = res.data.list;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.web-footer {
  //height: 258px;
  height: 150px;
  background: url(../../assets/foot_bg.jpg) center no-repeat;
  color: #fff;
  overflow: hidden;
  &-links {
    position: relative;
    padding: 32px 0 22px;
    border-bottom: 1px solid #0084cf;
    display: flex;
    flex-wrap: wrap;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -6px;
      width: 104px;
      height: 6px;
      background: #0084cf;
    }
    &-title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 50px;
    }
    &-list {
      flex: 1;
      padding-top: 8px;
      overflow: hidden;
      &-item {
        display: inline-block;
        margin-right: 20px;
        a {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &-wechat {
      //display: inline-block;
      //padding-top: 22px;
      //height: 127px;
      //text-align: center;
      //.icon {
      //  width: 104px;
      //  height: 106px;
      //}
      //.title {
      //  font-size: 14px;
      //}
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .privacy {
        margin-bottom: 15px;
        text-align: right;
        a {
          display: inline-block;
          padding: 8px 14px;
          background-color: #ffffff;
          color: #084e9b;
          border-radius: 3px;
          vertical-align: top;
          &:hover {
            background: #0084cf;
            color: #fff;
          }
        }
      }
      .copyright {
        color: #b8b8b8;
        span {
          margin-left: 15px;
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-footer {
    height: auto;
    padding-bottom: 50px;
    background-size: cover;
    &-links {
      padding: 50px 50px 30px;
      display: inline-block;
      &-title {
        width: 100%;
        font-size: 30px;
      }
      &-list {
        &-item {
          width: 100%;
          padding-bottom: 10px;
          margin-right: 0;
          a {
            font-size: 20px;
          }
        }
      }
    }
    &-bottom {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-wechat {
        height: auto;
        .title {
          font-size: 18px;
        }
        .icon {
          margin: auto;
        }
      }
      &-right {
        padding-top: 20px;
        align-items: center;
        justify-content: center;
        .privacy {
          a {
            font-size: 18px;
          }
        }
        .copyright {
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-footer {
    height: auto;
    padding-bottom: 26px;
    background-size: cover;
    &-links {
      padding: 20px 20px 15px;
      display: inline-block;
      &-title {
        width: 100%;
        font-size: 18px;
      }
      &-list {
        &-item {
          width: 100%;
          padding-bottom: 10px;
          margin-right: 0;
          a {
            font-size: 16px;
          }
        }
      }
    }
    &-bottom {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-wechat {
        height: auto;
        .title {
          font-size: 14px;
        }
        .icon {
          margin: auto;
        }
      }
      &-right {
        padding-top: 20px;
        align-items: center;
        justify-content: center;
        .privacy {
          a {
            padding: 5px 10px;
            font-size: 14px;
          }
        }
        .copyright {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}
</style>